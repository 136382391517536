import { gql } from '@apollo/client';

export const SEND_MESSAGE = gql`
  mutation SendMessage($args: ChatCreateInput!) {
    sendMessage(args: $args) {
      id
      createdAt
      orderId
      files {
        id
      }
      updatedAt
      userGroupId
      userGroup {
        name
        groupType
        id
      }
      message
    }
  }
`;

export const GET_ALL_MESSAGES_OF_ORDER = gql`
  query GetAllChatsOfOrder(
    $skip: Int,
    $take: Int,
    $where: ChatWhereInput
  ) {
    getAllChatsOfOrder(
      skip: $skip,
      take: $take,
      where: $where
    ) {
      notification {
        createdAt
        files {
          fileType
          key
          keyCompressed
          createdAt
          userId
          orderId
        }
        id
        orderId
        userGroup {
          name
          groupType
          id
        }
        userGroupId
        message
      },
      count
    }
  }
`;


export const GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER = gql`
  query GetLastUnreadOfEachOrder ($args: ChatInput!) {
    getLastUnreadOfEachOrder (args: $args) {
      notification {
        id
        createdAt
        userGroup {
          name
          groupType
          id
        }
        order {
          orderReference
          id
          status
          patient {
            firstName
            lastName
          }
        }
        message
        read
      }
      count
    }
  }
`;

export const GET_NUMBER_OF_NOTIFICATIONS = gql`
  query GetNotificationCount {
    getNotificationCount 
  }
`;

export const UPDATE_MESSAGES = gql`
  mutation UpdateMessages($data: ChatUpdateManyMutationInput!, $where: ChatWhereInput!) {
    updateMessages(data: $data, where: $where)
  }
`;

export const MARK_CHAT_AS_VIEWED = gql`
  mutation MarkChatAsViewed($where: ChatMarkAsUnreadWhereInput!) {
    markChatAsViewed(where: $where)
  }
`;

export const SET_LAST_CHAT_OF_ORDER_AS_UNREAD = gql`
  mutation MarkLastMessageAsUnread($orderId: String!) {
    markLastMessageAsUnread(orderId: $orderId)
  }
`;
