import { gql } from '@apollo/client';

export const COUNT_NUMBER_OF_NEW_CASES_FOR_STAFF = gql`
  query GetAllOrdersForStaff($where: OrderWhereInput) {
    getAllOrdersForStaff(where: $where) {
      totalCount
    }
  }
`;

export const GET_ALL_ORDERS_FOR_STAFF = gql`
  query GetAllOrdersForStaff (
    $skip: Int,
    $take: Int,
    $where: OrderWhereInput
  ) {
    getAllOrdersForStaff (
      skip: $skip,
      take: $take,
      where: $where
  ) 
  {
    orders {
      id
      orderReference
      isRush
      comment
      deliveryDate
      lastShippingDate
      status
      updatedAt
      createdAt
      isPhysicalPrint
      patient {
        firstName
        lastName
      }
      files {
        id
        fileType
      }
      user {
        lastName
        firstName
      }
      redo {
        id
      }
      orderComment {
        translatedComment
        comment
      }
      products {
        productType
        implantItem {
          itemMaterial {
            id
            label
          }
          itemShade {
            id
            label
          }
        }
        anatomyItem {
          itemShade {
            id
            label
          }
          itemMaterial {
            id
            label
          }
          inlayCoreScanPost
          inlayCoreMaterial {
            label
          }
        }
        removableItem {
          itemMaterial {
            id
            label
          }
          teethShade {
            id
            label
          }
          gingivaShade {
            id
            label
          }
        }
      }
    },
    totalCount
  }
}
`

export const GET_ORDER_BY_UNIQUE_ATTRIBUTE = gql`
  query GetOrderByUniqueAttribute($where: OrderWhereUniqueInput!) {
    getOrderByUniqueAttribute(where: $where) {
      id
      orderReference
      comment
      isRush
      deliveryDate
      lastShippingDate
      status
      createdAt
      isArchived
      confirmedAt
      isPhysicalPrint
      provider {
        id
        name
      }
      orderComment {
        translatedComment
        comment
      }
      patient {
        id
        firstName
        lastName
        birthdate
        sex
      }
      redo {
        id
      }
      files {
        id
        fileType
        key
      }
      user {
        lastName
        firstName
        userGroup {
          clinicNumber
        }
      }
      orderComment {
        id
        translatedComment
        comment
        type
      }
      history {
        status
        createdAt
      }
      products {
        id
        productType
        teeth
        itemPreferences
        implantItem {
          id
          itemType
          teeth
          itemMaterial {
            id
            label
          }
          itemShade {
            id
            label
          }
          itemBilling {
            firstMaterialLotNumber
            firstMaterialTraceabilityId
            id
          }
          abutmentMaterial {
            id
            label
          }
          abutmentType
          implantPlatform
          implantReference {
            brand
            label
            id
            platforms
          }
          retentionType
          scanBodySuggestion
          standardAbutmentSuggestion
          implantReferenceSuggestion
          teethshadeType
          multiShadeInfo {
            teethShadeSide
            itemShade {
              label
            }
          }
        }
        anatomyItem {
          id
          itemType
          teeth
          itemShade {
            id
            label
          }
          itemMaterial {
            id
            label
          }
          inlayCoreHasClavette
          inlayCoreScanPost
          inlayCoreMaterial {
            id
            label
          }
          itemBilling {
            firstMaterialLotNumber
            firstMaterialTraceabilityId
            id
          }
          teethshadeType
          multiShadeInfo {
            teethShadeSide
            itemShade {
              label
            }
          }
        }
        removableItem {
          id
          itemType
          teeth
          teethToManufacture
          workflowType
          isReplacement
          itemMaterial {
            id
            label
          }
          teethShade {
            id
            label
          }
          gingivaShade {
            id
            label
          }
          itemBilling {
            firstMaterialLotNumber
            firstMaterialTraceabilityId
            id
          }
        }
      }
    }
  }
`;

export const UPDATE_STATUS_AND_ADD_PRODIVER_GROUP_ID_FOR_MANY_ORDER = gql`
  mutation AddProviderToManyOrder($orderIds: [String!]!, $providerId: String!) {
    addProviderToManyOrder(orderIds: $orderIds, providerId: $providerId)
  }
`;
