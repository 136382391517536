import React from "react";
import {
  TextInput,
} from '@mantine/core';

export interface SearchBarProps {
  searchText: string,
  setSearchText: (val: string) => void
  onConfirm?: () => void
}

const SearchBar = ({...props}: SearchBarProps) => {
  return (
    <TextInput
      style={{
        width:'100%',
        marginRight:'10px'
      }}
      value={props.searchText}
      onChange={(e) => props.setSearchText(e.target.value)}
      placeholder="Search by order reference, firstname or lastname of patient/doctor"
      onKeyDown={(event) => {
        if (event.key === 'Enter' && props.onConfirm){ // Enter
          props.onConfirm();
        }
      }}
    />
  );
};

export default SearchBar;
