import React from 'react';
import './message.css';
import {
  Avatar,
  Badge
} from '@mantine/core';
import { getLetterOfUser, messages } from '../utils/chat.utils';
import { Image } from '../utils/carousel.utils';
import { UploadedFile } from './UploadedFile';

export interface MessageProps {
  message: messages
  position: "right" | "left"
  previousDate: Date | null
  previousMessageUser: string | null
  setRenderingFiles: (value: Image[]) => void
  setShowModal: (value: boolean) => void
}

export const Message = ({
  ...props
}: MessageProps) => {
  const createdAt = new Date(props.message.createdAt).toDateString()
  const previousDate = props.previousDate ? new Date(props.previousDate).toDateString() : null
  const messageBgColor = (props.position === "left") ? "#F0F2F5" : "#3693E9";
  const messageColor = (props.position === "left") ? "black" : "white";
  const flexDirection = (props.position === "left") ? "row" : "row-reverse";
  const isNotSameDay =
    previousDate &&
    previousDate !== createdAt ||
    previousDate === null;
  const isNotSameUser = props.previousMessageUser !== props.message.userGroup.id

  const getImages = () => {
    return props.message.files?.filter((file) => {
      const fileNameWithExtention = file.key.split('.')
      if (fileNameWithExtention.length > 0) {
        return ['jpg', 'jpeg', 'gif', 'png', 'piff', 'bmp'].includes(fileNameWithExtention[fileNameWithExtention.length - 1].toLocaleLowerCase())
      }
    }) ?? []
  }

  const getFiles = () : Image[] => {
    return props.message.files?.filter((file) => {
      const fileNameWithExtention = file.key.split('.')
      if (fileNameWithExtention.length > 0) {
        return ['zip', 'stl', 'bmp', 'xls', 'xlsx', 'dcm'].includes(fileNameWithExtention[fileNameWithExtention.length - 1].toLocaleLowerCase())
      }
    }) ?? []
  }

  return (
    <div style={{ float: props.position, width: '100%' }}>
      {
        (isNotSameDay) &&
        <Badge className='date'>
          {
            (createdAt === (new Date().toDateString())) ?
              'Today' :
              createdAt
          }
        </Badge>
      }
      {
        (isNotSameUser || isNotSameDay) &&
        <div style={{ width: '100%', display: 'flex', flexDirection: flexDirection }}>
          <div
            className='user-block'
            style={{ float: props.position }}
          >
            <Avatar
              variant="filled"
              color={props.position === 'left' ? "rgba(57, 74, 204, 1)" : "violet"}
            >
              {getLetterOfUser(props.message.userGroup.name, props.message.userGroup.groupType)}
            </Avatar>
            <p
              className='user-block-text'
            >
              {
                props.position === "right" ?
                  "Me" :
                  `${props.message.userGroup.name}`
              }
            </p>
          </div>
        </div>
      }
      <div
        className='message'
        style={{
          backgroundColor: messageBgColor,
          color: messageColor,
          float: props.position
        }}
      >
        {props.message.message &&
          <p
            style={{
              margin: '10px 15px',
              whiteSpace: "pre-wrap",
            }}
          >
            {props.message.message}
          </p>
        }
        {getImages().length > 0 &&
          <div
            style={{
              overflow: 'hidden',
              position: 'relative',
              display: 'grid',
              cursor: 'pointer',
              gridTemplateColumns:
                getImages()?.length > 1 ? '1fr 1fr' : '1fr',
              gap: '3px',
              borderRadius:
                props.message.message ?
                  '0 0 15px 15px' :
                  '15px'
            }}
            onClick={() => {
              props.setShowModal(true);
              props.setRenderingFiles(getImages())
            }}
          >
            {getImages().length > 1 &&
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  zIndex: '5',
                  backgroundColor: '#39393969'
                }}
              >
                <p
                  style={{
                    margin: 'auto',
                    fontSize: '25px',
                  }}
                >
                  {getImages().length} images
                </p>
              </div>
            }
            {getImages()
              .filter((file) => {
                const fileNameWithExtention = file.key.split('.')
                if (fileNameWithExtention.length > 0) {
                  return ['jpg', 'jpeg', 'gif', 'png', 'piff', 'bmp'].includes(fileNameWithExtention[fileNameWithExtention.length - 1].toLocaleLowerCase())
                }
              })
              .filter((val, index) => index < 4)
              .map((file) =>
                <img
                  src={file.pathCompressed ?? file.path}
                  style={{
                    height: '100%',
                    position: 'relative',
                    margin: 'auto',
                    maxWidth: '100px'
                  }}
                  alt={file.keyCompressed ?? file.key}
                />
              )}
          </div>
        }
        {getFiles() &&
          <UploadedFile
            tempFiles={getFiles()}
            isTempFile={false}
          />
        }
      </div>
    </div>
  );
};
