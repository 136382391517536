import { useState } from "react";
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {
  Button,
  Space,
  Box,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { MantineProvider } from '@mantine/core';

import {
  ItemType,
  SelectInputRef,
  TextInputRef,
  STYLE,
  DentalArchesSvg,
  ArchType,
  getArchFromTeeth,
  ProductItemType,
  UpperArchTeeth,
  LowerArchTeeth,
} from '@jasper/shared';

import {
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_ANATOMY_ITEM,
  UPDATE_REMOVABLE_ITEM,
  UPDATE_IMPLANT_ITEM,
  UPDATE_ALL_ITEM_TEETH_OF_PRODUCTS,
} from '../../../gql/';

const CreateProductComponent = ({ closeModal, orderId, refetch, product, isRemovableItem }) => {
  const [createProduct] = useMutation(CREATE_PRODUCT);
  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  const [updateAllTeethOfItem] = useMutation(UPDATE_ALL_ITEM_TEETH_OF_PRODUCTS);
  const [selectedTeeth, setSelectedTeeth] = useState<number[]>(product?.teeth ?? []);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm({
    defaultValues: {
      productType: product?.productType,
    }
  });

  const watchProductType = watch("productType");

  const submit = async(data) => {
    try {
      if (product?.id){
        await updateProduct({
          variables: {
            data: {
              productType: {
                set: data.productType,
              },
              teeth: selectedTeeth,
            },
            where: {
              id: product.id
            }
          }
        });
        await updateAllTeethOfItem({
          variables: {
            productId: product?.id,
            teeth: JSON.stringify(selectedTeeth)
          }
        })
      } else {
        await createProduct({
          variables:{
            args: {
              teeth: selectedTeeth,
              productType: data.productType,
              order: {
                connect: {
                  id: orderId
                }
              },
              itemPreferences: undefined
            }
          }
        });
        notifications.show({
          title: "Product created",
          color: "green",
          message: "",
        });
      }
      refetch();
    } catch (e) {
      notifications.show({
        title: "Error while trying to create or update product",
        color: "red",
        message: "",
      });
    }
    closeModal();
  };

  return (
    <MantineProvider>
      <SelectInputRef
        name="productType"
        data={Object.keys(ItemType)}
        label="Product type"
        errors={errors}
        control={control}
      />
      <Space h="md"/>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DentalArchesSvg
          teeth={selectedTeeth}
          width={275}
          height={366}
          onClick={(tooth) => {
            if (isRemovableItem(watchProductType)){
              if (getArchFromTeeth([tooth]) === ArchType.MAXILLAIRE){
                if (selectedTeeth.includes(tooth)){
                  setSelectedTeeth(selectedTeeth.filter((t) => !UpperArchTeeth.includes(t)));
                } else {
                  setSelectedTeeth([...UpperArchTeeth, ...selectedTeeth])
                }
              } else {
                if (selectedTeeth.includes(tooth)){
                  setSelectedTeeth(selectedTeeth.filter((t) => !LowerArchTeeth.includes(t)));
                } else {
                  setSelectedTeeth([...LowerArchTeeth, ...selectedTeeth])
                }
              }
            } else {
              const index = selectedTeeth.indexOf(tooth);
              if (index >= 0){
                setSelectedTeeth(selectedTeeth.filter((t) => t !== tooth));
                return;
              }
              setSelectedTeeth([...selectedTeeth, tooth])
            }
          }}
        />
      </Box>
      <Space h="md"/>
      <div style={{ textAlign: 'center' }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={handleSubmit(submit)}
        >
          Submit
        </Button>
      </div>
    </MantineProvider>
  );
};

export default CreateProductComponent;
