import {
  useState,
  useEffect,
} from "react";
import {
  Text,
  Space,
  Button,
} from "@mantine/core";
import {
  useForm,
} from "react-hook-form";
import {
  useQuery,
  useMutation,
} from "@apollo/client";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";

import SelectorButtonRef from "../SelectorButtonRef";
import SelectInputRef from "../SelectInputRef";
import ShadeSelection from "../shadeSelection";
import {
  FixedProsthesisShadeType,
  TeethShadeType,
  TeethShadeSide,
  ItemType,
} from "../../types/enums";
import {
  STYLE,
  YES_OR_NO_CHOICE,
} from "../../types/constants";
import {
  GET_SCAN_POST_LIST,
  DELETE_MANY_ANATOMY_ITEMS,
  CREATE_ANATOMY_ITEM,
} from "../../gql/items";
import {
  formatChoicesData,
  deduceShadeType,
  checkisInlayCoreOnly,
} from '../../utils/item.utils';
import {
  removeTeethFromBridgeOnInlayCore,
  getAllNonSplintedCrown,
  getAllPossibleSplintedCrown,
  isBridgeOnInlayCore,
} from "../../utils/product.utils";

const CreateNewInlayCoreComponent = ({
  productType,
  order,
  product_id,
  refetch,
  materialFormChoices,
  anatomyItemChoicesData,
  accountProductPreferences,
}) => {
  const [deleteManyAnatomyItem] = useMutation(DELETE_MANY_ANATOMY_ITEMS);
  const [createAnatomyItem] = useMutation(CREATE_ANATOMY_ITEM);

  const navigate = useNavigate();
  const location = useLocation();

  const savedItem = (location?.state?.product?.anatomyItem ?? []).find((item) => item.itemType === ItemType.INLAY_CORE);

  const product = (order?.products ?? []).find((product) => product.id === product_id);

  const isNotBridgeOnInlayCore =
    !isBridgeOnInlayCore((order?.products ?? []), product) ||
    productType === ItemType.BRIDGE;

  const { data: getScanPostsData, loading: getScanPostsDataLoading } = useQuery(GET_SCAN_POST_LIST);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    defaultValues: {
      isInlayCoreClaveted:savedItem?.inlayCoreHasClavette ?? false,
      inlayCoreMaterial: savedItem?.inlayCoreMaterial?.id,
      inlayCoreScanPost: savedItem?.inlayCoreScanPost,
      material: savedItem?.itemMaterial?.id,
      shade: savedItem?.itemShade?.id,
      inlayCoreScanPostType: savedItem?.inlayCoreScanPost ? savedItem?.inlayCoreScanPost.split("_")[0] : undefined,
      isInlayCoreOnly: checkisInlayCoreOnly(product, isBridgeOnInlayCore),
      isRichmondCrown: savedItem?.itemType === ItemType.RICHMOND_CROWN,
      shadeType: deduceShadeType(savedItem?.itemShade?.id, order?.user?.id, accountProductPreferences),
      isSplintedCrown: false,
    }
  });

  const watchInlayCoreClaveted = watch("isInlayCoreClaveted");
  const watchIsInlayCoreOnly = watch("isInlayCoreOnly");
  const watchIsRichmondCrown = watch("isRichmondCrown");
  const watchInlayCoreScanPostType = watch("inlayCoreScanPostType");
  const watchShadeType = watch("shadeType");
  const watchIsSplintedCrown = watch("isSplintedCrown");
  const watchIsMultiShade = watch("isMultiShade");
  const watchCrownMaterial = watch("material")

  const inlayCoreMaterialFormChoices = formatChoicesData(
    (anatomyItemChoicesData?.getItemMaterialsWhere ?? []).filter(
      (m: any) => m.inlayCore === true,
    ),
    watchInlayCoreClaveted,
  );

  const deduceItemTypeFromOptions = (
    data: AnatomyItemFormData,
    product: ProductFromGetOrderByUniqueAttributeQuery,
  ) => {
    if (data.isInlayCoreOnly) {
      return ItemType.INLAY_CORE;
    }
    if (data.isRichmondCrown) {
      return ItemType.RICHMOND_CROWN;
    }
    return productType ?? undefined;
  };

  const onSubmit = async (data) => {
    if (savedItem?.teeth){
      await deleteManyAnatomyItem({
        variables: {
          where: {
            productId: {
              equals: product_id,
            },
            teeth: {
              equals: savedItem.teeth,
            },
          },
        }
      })
    }
    const allNonPossibleSplintedCrown: number[] = removeTeethFromBridgeOnInlayCore(
      getAllNonSplintedCrown(
        product?.teeth ?? [],
      ),
      (order?.products ?? []),
    );
    const allSplinteCrown: number[][] = getAllPossibleSplintedCrown(
      product?.teeth ?? [],
    );
    const productToUpdate = [];
    if (data.isSplintedCrown && !data.isInlayCoreOnly && isNotBridgeOnInlayCore) {
      allSplinteCrown.map(splintedCrown => {
        const splintedCrownTeeth = removeTeethFromBridgeOnInlayCore(splintedCrown, (order?.products ?? []));
        if (splintedCrownTeeth.length > 0){
          productToUpdate.push({
            inlayCoreHasClavette: data.isInlayCoreClaveted,
            inlayCoreScanPost: data.inlayCoreScanPost ?? undefined,
            bridgeType: data.bridgeType ?? undefined,
            inlayCoreMaterial: data.inlayCoreMaterial
              ? {
                  connect: {
                    id: data.inlayCoreMaterial,
                  },
                }
              : undefined,
            itemMaterial:(data.material && !watchIsInlayCoreOnly)
              ? {
                  connect: {
                    id: data.material,
                  },
                }
              : undefined,
            itemShade: (data.shade && !watchIsMultiShade)
              ? {
                  connect: {
                    id: data.shade,
                  },
                }
              : undefined,
            itemType: ItemType.SPLINTED_CROWN,
            teeth: splintedCrownTeeth,
            product: {
              connect: {
                id: product_id,
              },
            },
            multiShadeInfo: watchIsMultiShade
            ? {
              createMany: {
                data: [
                  ...data.gingivalShade
                  ? [{
                    itemShadeId: data.gingivalShade,
                    teethShadeSide: TeethShadeSide.Gingival,
                  }] : [],
                  ...data.baseShade
                  ? [{
                    itemShadeId: data.baseShade,
                    teethShadeSide: TeethShadeSide.Base,
                  }] : [],
                  ...data.incisalShade
                  ? [{
                    itemShadeId: data.incisalShade,
                    teethShadeSide: TeethShadeSide.Incisal,
                  }] : [],
                ],
              },
            } : undefined,
          });
        }
      });
      if (allNonPossibleSplintedCrown.length > 0 && isNotBridgeOnInlayCore) {
        productToUpdate.push({
          inlayCoreHasClavette: data.isInlayCoreClaveted,
          inlayCoreMaterial: data.inlayCoreMaterial
            ? {
                connect: {
                  id: data.inlayCoreMaterial,
                },
              }
            : undefined,
          itemShade: (data.shade && !watchIsMultiShade)
            ? {
                connect: {
                  id: data.shade,
                },
              }
            : undefined,
          inlayCoreScanPost: data.inlayCoreScanPost ?? undefined,
          itemType: ItemType.CROWN ?? undefined,
          bridgeType: data.bridgeType ?? undefined,
          teeth: allNonPossibleSplintedCrown,
          itemMaterial:
            (data.material && !watchIsInlayCoreOnly)
              ? {
                  connect: {
                    id: data.material,
                  },
                }
              : undefined,
          product: {
            connect: {
              id: product.id,
            },
          },
          teethshadeType: watchIsMultiShade ? TeethShadeType.MultiShade : TeethShadeType.SingleShade,
          multiShadeInfo: watchIsMultiShade
          ? {
            createMany: {
              data: [
                ...data.gingivalShade
                ? [{
                  itemShadeId: data.gingivalShade,
                  teethShadeSide: TeethShadeSide.Gingival,
                }] : [],
                ...data.baseShade
                ? [{
                  itemShadeId: data.baseShade,
                  teethShadeSide: TeethShadeSide.Base,
                }] : [],
                ...data.incisalShade
                ? [{
                  itemShadeId: data.incisalShade,
                  teethShadeSide: TeethShadeSide.incisal,
                }] : [],
              ],
            },
          } : undefined,
        });
      }
    }
    const itemTypeToUpdate = deduceItemTypeFromOptions(data, product);
    if (
      productType === ItemType.CROWN
        ? !data.isSplintedCrown
        : true
    ) {
      productToUpdate.push({
        inlayCoreHasClavette: data.isInlayCoreClaveted,
        inlayCoreMaterial: data.inlayCoreMaterial
          ? {
              connect: {
                id: data.inlayCoreMaterial,
              },
            }
          : undefined,
        itemShade: (data.shade && !watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS")
          ? {
              connect: {
                id: data.shade,
              },
            }
          : undefined,
        inlayCoreScanPost: data.inlayCoreScanPost ?? undefined,
        itemType: itemTypeToUpdate ?? undefined,
        bridgeType: data.bridgeType ?? undefined,
        teeth: product?.teeth ?? [],
        itemMaterial:
          (data.material && !watchIsInlayCoreOnly)
            ? {
                connect: {
                  id: data.material,
                },
              }
            : undefined,
        product: {
          connect: {
            id: product.id,
          },
        },
        teethshadeType: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MultiShade : TeethShadeType.SingleShade,
        multiShadeInfo: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
        ? {
          createMany: {
            data: [
              ...data.gingivalShade
              ? [{
                itemShadeId: data.gingivalShade,
                teethShadeSide: TeethShadeSide.Gingival,
              }] : [],
              ...data.baseShade
              ? [{
                itemShadeId: data.baseShade,
                teethShadeSide: TeethShadeSide.Base,
              }] : [],
              ...data.incisalShade
              ? [{
                itemShadeId: data.incisalShade,
                teethShadeSide: TeethShadeSide.Incisal,
              }] : [],
            ],
          },
        } : undefined,
      });
    }
    const crownTeeths = removeTeethFromBridgeOnInlayCore(product?.teeth ?? [], (order?.products ?? []));
    if (
      product.productType === ItemType.INLAY_CORE &&
      !data.isInlayCoreOnly &&
      !data.isRichmondCrown &&
      !data.isSplintedCrown &&
      isNotBridgeOnInlayCore &&
      crownTeeths.length > 0
    ) {
      productToUpdate.push({
        inlayCoreHasClavette: data.isInlayCoreClaveted,
        inlayCoreScanPost: data.inlayCoreScanPost ?? undefined,
        bridgeType: data.bridgeType ?? undefined,
        inlayCoreMaterial: data.inlayCoreMaterial
          ? {
              connect: {
                id: data.inlayCoreMaterial,
              },
            }
          : undefined,
        itemMaterial: (data.material && !watchIsInlayCoreOnly)
          ? {
              connect: {
                id: data.material,
              },
            }
          : undefined,
        itemShade: (data.shade && !watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS")
          ? {
              connect: {
                id: data.shade,
              },
            }
          : undefined,
        itemType: ItemType.CROWN,
        teeth: crownTeeths,
        product: {
          connect: {
            id: product.id,
          },
        },
        teethshadeType: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MultiShade : TeethShadeType.SingleShade,
        multiShadeInfo: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
        ? {
          createMany: {
            data: [
              ...data.gingivalShade
              ? [{
                itemShadeId: data.gingivalShade,
                teethShadeSide: TeethShadeSide.Gingival,
              }] : [],
              ...data.baseShade
              ? [{
                itemShadeId: data.baseShade,
                teethShadeSide: TeethShadeSide.Base,
              }] : [],
              ...data.incisalShade
              ? [{
                itemShadeId: data.incisalShade,
                teethShadeSide: TeethShadeSide.Incisal,
              }] : [],
            ],
          },
        } : undefined,
      });
    }
    await productToUpdate.map(async item => {
      await createAnatomyItem({
        variables: {
          args: item,
        },
      });
    });
    await refetch();
    navigate(`/orders/${order?.id}/edit/`);
  };

  useEffect(() => {
    setValue(
      "isInlayCoreOnly",
      checkisInlayCoreOnly(product, isBridgeOnInlayCore),
    );
  }, [product]);

  if (getScanPostsDataLoading){
    return ("... Loading ...");
  }

  return (
    <>
      <Text style={{ textAlign: "center" }} fw={700}>{savedItem?.id ? "Modification" : "Ajout"} d'un Inlay-Core</Text>
      <SelectorButtonRef
        label="Inlay-Core Claveté ?"
        name="isInlayCoreClaveted"
        data={YES_OR_NO_CHOICE}
        onSubmit={(selectedValue) => setValue("isInlayCoreClaveted", selectedValue)}
        control={control}
        errors={errors}
        watch={watchInlayCoreClaveted}
        required={true}
      />
      <SelectorButtonRef
        label="Inlay-Core seulement (sans couronne)?"
        name="isInlayCoreOnly"
        data={YES_OR_NO_CHOICE}
        onSubmit={(selectedValue) => setValue("isInlayCoreOnly", selectedValue)}
        control={control}
        errors={errors}
        watch={watchIsInlayCoreOnly}
        required={true}
      />
      {!watchIsInlayCoreOnly &&
        <SelectorButtonRef
          label="Fabrication d’une couronne Richmond?"
          name="isRichmondCrown"
          data={YES_OR_NO_CHOICE}
          onSubmit={(selectedValue) => setValue("isRichmondCrown", selectedValue)}
          control={control}
          errors={errors}
          watch={watchIsRichmondCrown}
          required={true}
        />
      }
      <SelectorButtonRef
        label="Choisissez votre type de Scan Post"
        name="inlayCoreScanPostType"
        data={getScanPostsData?.getScanPostsData?.types ?? []}
        onSubmit={(selectedValue) => setValue("inlayCoreScanPostType", selectedValue)}
        control={control}
        errors={errors}
        watch={watchInlayCoreScanPostType}
        required={true}
      />
      <SelectInputRef
        label="Choisissez le scan post utilisé"
        name="inlayCoreScanPost"
        data={watchInlayCoreScanPostType
          ? (
              getScanPostsData?.getScanPostsData.scanPosts as {
                type: string;
                value: string;
                label: string;
              }[]
            ).filter(scanPost => scanPost.type === watchInlayCoreScanPostType)
          : getScanPostsData?.getScanPostsData.scanPosts
        }
        control={control}
        errors={errors}
        required={true}
      />
      <SelectInputRef
        label="Matériau d'Inlay-Core"
        name="inlayCoreMaterial"
        data={inlayCoreMaterialFormChoices}
        control={control}
        errors={errors}
        required={true}
      />
      {(productType === ItemType.INLAY_CORE || productType === ItemType.CROWN) &&
        getAllPossibleSplintedCrown(product?.teeth ?? []).length > 0 &&
        !watchIsInlayCoreOnly &&
        !watchIsRichmondCrown &&
        isNotBridgeOnInlayCore && (
          <SelectorButtonRef
            label="Faut-il solidariser les couronnes ?"
            name="isSplintedCrown"
            data={YES_OR_NO_CHOICE}
            onSubmit={(selectedValue) => setValue("isSplintedCrown", selectedValue)}
            control={control}
            errors={errors}
            watch={watchIsSplintedCrown}
            required={true}
          />
      )}
      {!watchIsInlayCoreOnly &&
        <>
          <SelectInputRef
            label="Matériau de la couronne"
            name="material"
            data={materialFormChoices}
            control={control}
            errors={errors}
            required={true}
          />
          {watchCrownMaterial !== "METAL_NON_PRECIOUS" &&
            <ShadeSelection
              watchShadeType={watchShadeType}
              control={control}
              errors={errors}
              anatomyItemChoicesData={anatomyItemChoicesData}
              onSelect={(selectedValue) => setValue("shadeType", selectedValue)}
              order={order}
              accountProductPreferences={accountProductPreferences}
            />
          }
        </>
      }
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={handleSubmit(onSubmit)}
          style={{ backgroundColor: STYLE.primary }}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default CreateNewInlayCoreComponent;
