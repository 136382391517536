import React, {useEffect} from "react";
import {
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import {
  GET_LAST_UNREAD_HELP_COMMENT_OF_EACH_ORDER,
} from "/@/gql/";
import { Button, Table } from "@mantine/core";
import { getApiUrl } from '../../config';
import {io} from "socket.io-client";
import { SET_LAST_CHAT_OF_ORDER_AS_UNREAD } from "../../gql/chat";

const __TAKE__ = 10;

const HelpList = () => {

  const navigate = useNavigate();
  // const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const skip = searchParams.get("page")? (parseInt(searchParams.get("page"), 10) * 20) : 0;
  const pageNumber = parseInt(searchParams.get("page"), 10) ?? 0

  const { data, refetch } = useQuery(
    GET_LAST_UNREAD_HELP_COMMENT_OF_EACH_ORDER,
    {
      variables: {
        args: {
          skip: skip,
          take: __TAKE__
        }
      },
      fetchPolicy: "network-only",
    }
  );

  // const [markAsUnread] = useMutation(SET_LAST_CHAT_OF_ORDER_AS_UNREAD);
  

  return (
    <>
    
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Order reference</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Last message</Table.Th>
          <Table.Th>Patient</Table.Th>
          <Table.Th>Date</Table.Th>
          <Table.Th>Actions</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {data && (data?.getHelpMsgList?.notification ?? []).map((helpMsg) => {
          return (
            <Table.Tr key={helpMsg.order.id} style={helpMsg.read === false ? {fontWeight: 'bold'} : {}}>
              <Table.Td>{helpMsg.order.orderReference}</Table.Td>
              <Table.Td>{helpMsg.order.status}</Table.Td>
              <Table.Td>{helpMsg.comment.length > 50 ? helpMsg.comment.slice(0,50) + '...' : helpMsg.comment}</Table.Td>
              <Table.Td>{helpMsg.order.patient.firstName} {helpMsg.order.patient.lastName}</Table.Td>
              <Table.Td>
                {
                  new Date(helpMsg.createdAt).toDateString() === new Date().toDateString() ?
                    new Date(helpMsg.createdAt).toLocaleTimeString().split(':').splice(0,2).join(':')
                    : new Date(helpMsg.createdAt).toLocaleDateString()
                }
              </Table.Td>
              <Table.Td style={{display: 'flex', gap: '3px', width:'max-content'}}>
                <Button onClick={() => navigate(`/orders/${helpMsg.order.id}`)}>
                  View order
                </Button>
              </Table.Td>
            </Table.Tr>
          )
        })}
      </Table.Tbody>
    </Table>
    {data && ((data?.getHelpMsgList?.notification ?? []).length > 0) &&
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        <div>
          {pageNumber > 0 &&
            <Button
              onClick={() => {
                navigate(`/help-demands?page=${pageNumber - 1}`)
              }}
            >
              Previous page
            </Button>
          }
        </div>
        <div style={{ marginTop: "1rem" }}>
          Page {pageNumber + 1}
        </div>
        <div>
          {(data?.getHelpMsgList?.notification ?? []).length % __TAKE__ === 0
            && data?.getHelpMsgList?.count !== skip + __TAKE__
            &&
            <Button
              onClick={() => {
                navigate(`/help-demands?page=${pageNumber + 1}`)
              }}
            >
              Next page
            </Button>
          }
        </div>
      </div>
    }
    </>
  );
};

export default HelpList;
