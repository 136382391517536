import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { 
  GET_ORDER_BY_UNIQUE_ATTRIBUTE,
  GET_ALL_MESSAGES_OF_ORDER,
  SEND_MESSAGE,
  UPDATE_MESSAGES,
  UPDATE_HELP_MESSAGES
} from '/@/gql';
import { FileType, OrderViewPage, useFiles  } from '@jasper/shared';
import { UseAuth } from '/@/contexts';
import { useEffect, useState } from 'react';
import {io} from "socket.io-client";
import { getApiUrl } from '../../config';
import { MARK_CHAT_AS_VIEWED } from '../../gql/chat';
const __TAKE__ = 5;

const OrderView = () => {

  const { order_id } = useParams();
  const { user } = UseAuth();

  if(!order_id) {
    return
  }

  const { data, refetch } = useQuery(
    GET_ORDER_BY_UNIQUE_ATTRIBUTE,
    {
      variables: {
        where: {
          id:  order_id
        }
      }
    }
  );
  const {getFileUrl, uploadFileToS3} = useFiles(order_id);

  const [message, setMessage] = useState('');
  const [scrollBottom, setScrollBottom] = useState(true);
  const [filesToSend, setFilesToSend] = useState<File[]>([]);
  const [page, setPage] = useState(0);
  const [messagesToShow, setMessageToShow] = useState<any[]>([])
  const [newMessage, setNewMessage] = useState<any>()
  const [hasMore, setHasMore] = useState<boolean>(false)
  
  const getFilesWithPath = async(messages) => {
    return await Promise.all(
      messages.map(async (msg) => {
        const updatedFiles = await Promise.all(
          msg.files.map(async (file) => {
            try {
              return {
                path: await getFileUrl(file.key),
                pathCompressed: await getFileUrl(file.keyCompressed ?? file.key),
                key: file.key,
                keyCompressed: file.keyCompressed,
                fileType: file.fileType,
              };
            } catch (error) {
              console.error(`Error fetching path for file key: ${file.key}`, error);
              return { key: file.key, fileType: file.fileType, path: null };
            }
          })
        );
        return { ...msg, files: updatedFiles };
      })
    );
  };
  
  const { data: messages, loading, refetch: refetchMessage } = useQuery(
    GET_ALL_MESSAGES_OF_ORDER,
    {
      variables: {
        take: __TAKE__,
        skip: __TAKE__ * page,
        where: {
          orderId: {
            equals: order_id,
          },
        },
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (data && data?.getAllChatsOfOrder && data?.getAllChatsOfOrder.count) {
          const newMessages = data?.getAllChatsOfOrder.notification ?? []; 
          getFilesWithPath(newMessages).then((processedMessages) => {
            if (processedMessages.length === newMessages.length) {
              setMessageToShow((prev) => [...(processedMessages.toReversed()), ...prev]);
              setScrollBottom(false)
      
              if ([...(processedMessages), ...messagesToShow].length === data?.getAllChatsOfOrder.count) {
                setHasMore(false);
              }
              else {
                setHasMore(true);
              }
            }
          });
        }
      },
    }
  );

  const fetchMoreMessages = () => {
    if (hasMore) {
      setPage(page => page + 1)
    }
  };
  const [send] = useMutation(SEND_MESSAGE);
  const [updateMessages] = useMutation(UPDATE_MESSAGES);
  const [setChatMessageAsRead] = useMutation(MARK_CHAT_AS_VIEWED);
  const [updateHelpMessages] = useMutation(UPDATE_HELP_MESSAGES);
  const markmessagesAsRead = () => {
    setChatMessageAsRead(
      {
        variables: {
          where: {
            orderId: {
              equals: order_id
            },
            userGroupId: {
              equals: user?.userGroupId
            }
          }
        }
      }
    )
    updateMessages(
      {
        variables: {
          data: {
            read: {
              set: true
            }
          },
          where: {
            orderId: {
              equals: order_id
            },
            userGroupId: {
              not: {
                equals: user?.userGroupId
              }
            }
          }
        }
      }
    )
    updateHelpMessages(
      {
        variables: {
          data: {
            read: {
              set: true
            }
          },
          where: {
            orderId: {
              equals: order_id
            }
          }
        }
      }
    )
  }

  const sendMessage = async () => {
    if(filesToSend.length !== 0) { 
      uploadFileToS3(filesToSend, FileType.CHAT_FILE).then(async(data) => {
        if(data && data.length !== 0 ) {
          await send(
            {
              variables: {
                args: {
                  createdAt: new Date(),
                  order: {
                    connect: {
                      id: order_id
                    }
                  },
                  updatedAt: new Date(),
                  userGroup: {
                    connect: {
                      id: user.userGroupId
                    }
                  },
                  message: message,
                  files:{
                    create: data
                  }
                },
              }
            }
          )
        }
        setFilesToSend([])
      })
    }
    else {
      await send(
        {
          variables: {
            args: {
              createdAt: new Date(),
              order: {
                connect: {
                  id: order_id
                }
              },
              updatedAt: new Date(),
              userGroup: {
                connect: {
                  id: user.userGroupId
                }
              },
              message: message,
            },
          }
        }
      )
    }
  }

  useEffect(() => {
    markmessagesAsRead()
    const socket = io(getApiUrl(),
      {
        query: {
          type: 'chat',
          user_group_id: user?.userGroupId,
          order_id: order_id
        },
      });

    socket.connect()

    socket.on("disconnect", (reason) => {
      socket.connect();
    });

    socket.on('chat', (data) => {
      markmessagesAsRead()
      if(data.files.length > 0){
        getFilesWithPath([data]).then((msg)=> {
          setNewMessage(msg[0])
          setScrollBottom(true)
        })
      }
      else {
        data.files = []
        setNewMessage(data)
        setScrollBottom(true)
      }
    })
    return () => {
      socket.disconnect()
      socket.off('connect')
      socket.off('disconnect')
      socket.off('chat')
    }
  }, [])

  useEffect(()=>{
    if(newMessage) {
      setMessageToShow([...messagesToShow, newMessage])
    }
  }, [newMessage])

  return (
    <OrderViewPage
      order={data?.getOrderByUniqueAttribute}
      user={user}
      refetch={refetch}
      setMessage={setMessage}
      messages={[...messagesToShow]}
      sendMessage={sendMessage}
      filesToSend={filesToSend}
      setFilesToSend={(files: File[]) => setFilesToSend(files)}
      loading={loading}
      hasMore={hasMore} 
      fetchMoreMessages={fetchMoreMessages}
      scrollBottom={scrollBottom}
    />
  )
};


export default OrderView;
