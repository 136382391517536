import { gql } from '@apollo/client';

export const CREATE_PRODUCT = gql`
  mutation CreateProductManual($args: ProductCreateInput!) {
    createProductManual(args: $args) {
      id
    }
  }
`;

export const DELETE_MANY_PRODUCTS = gql`
  mutation DeleteManyProduct($where: ProductWhereInput) {
    deleteManyProduct(where: $where)
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($data: ProductUpdateInput!, $where: ProductWhereUniqueInput!) {
    updateProduct(data: $data, where: $where) {
      id
    }
  }
`;

export const UPDATE_ALL_ITEM_TEETH_OF_PRODUCTS = gql`
  mutation UpdateAllItemTeethOfProduct($productId: String!, $teeth: String!) {
    updateAllItemTeethOfProduct(productId: $productId, teeth: $teeth)
  }
`
