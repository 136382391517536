import React, { useEffect } from "react";
import {
  useForm,
} from "react-hook-form";
import {
  Button,
} from "@mantine/core";
import {
  useMutation,
} from "@apollo/client";
import { notifications } from "@mantine/notifications";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";

import SelectorButtonRef from "../../SelectorButtonRef"
import {
  YES_OR_NO_CHOICE,
  SPLINT_TYPE,
  SPLINT_HARDNESS_CHOICE,
  STYLE,
  UpperArchTeeth,
  LowerArchTeeth,
} from "../../../types/constants";
import {
  DELETE_MANY_REMOVABLE_ITEMS,
  CREATE_REMOVABLE_ITEM,
} from "../../../gql/items";
import {
  UPDATE_PRODUCT,
} from "../../../gql/product";
import {
  ItemType,
} from "../../../types/enums";

const CreateNewSplint = ({ product_id, order, refetch }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const savedItem = (location?.state?.product?.removableItem ?? []).find((item) => 
    item.itemType === ItemType.NIGHT_GUARD
    || item.itemType === ItemType.RETAINER
    || item.itemType === ItemType.BLEACHING_TRAY
  );
  const product = (order?.products ?? []).find((product) => product.id === product_id);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    defaultValues: {
      splintType: savedItem?.itemType,
      isBothJaws: (product?.removableItem ?? []).length === 2,
      splintMaterial: savedItem?.itemMaterial?.id,
    }
  });

  useEffect(() => {
    if ((product?.removableItem ?? []).length === 2){
      setValue("isBothJaws", true);
    }
  }, [(product?.removableItem ?? []).length]);

  const [deleteManyRemovableItem] = useMutation(DELETE_MANY_REMOVABLE_ITEMS);
  const [createRemovableItem] = useMutation(CREATE_REMOVABLE_ITEM);
  const [updateProduct] = useMutation(UPDATE_PRODUCT);

  const watchSplintType = watch("splintType");
  const watchIsBothJaw = watch("isBothJaws");
  const watchSplintMaterial = watch("splintMaterial");

  const onSubmit = async(data) => {
    try {
      if (savedItem?.teeth){
        await deleteManyRemovableItem({
          variables: {
            where: {
              productId: {
                equals: product_id,
              },
            },
          }
        })
      }
      if (data.isBothJaws){
        await createRemovableItem({
          variables: {
            args: {
              itemType: data.splintType,
              isBothJaws: data.isBothJaws,
              itemMaterial: {
                connect: {
                  id: data.splintMaterial,
                }
              },
              product: {
                connect: {
                  id: product_id
                }
              },
              teeth: UpperArchTeeth,
            }
          }
        });
        await createRemovableItem({
          variables: {
            args: {
              itemType: data.splintType,
              isBothJaws: data.isBothJaws,
              itemMaterial: {
                connect: {
                  id: data.splintMaterial,
                }
              },
              product: {
                connect: {
                  id: product_id
                }
              },
              teeth: LowerArchTeeth,
            }
          }
        });
      } else {
        await createRemovableItem({
          variables: {
            args: {
              itemType: data.splintType,
              isBothJaws: data.isBothJaws,
              itemMaterial: {
                connect: {
                  id: data.splintMaterial,
                }
              },
              product: {
                connect: {
                  id: product_id
                }
              },
              teeth: product.teeth,
            }
          }
        });
      }
      await updateProduct({
        variables: {
          where: {
            id: product_id
          },
          data: {
            teeth: data.isBothJaws ? [...UpperArchTeeth, ...LowerArchTeeth] : product.teeth,
          }
        }
      });
      await refetch();
      navigate(`/orders/${order?.id}/edit/`);
      notifications.show({
        title: "Modification saved corretly",
        color: "green",
        message: "",
      }); 
      return;
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to create or update item",
        color: "red",
        message: "",
      }); 
      return;
    }
  };

  return (
    <>
      <SelectorButtonRef
        label="Type de gouttière"
        name="splintType"
        data={SPLINT_TYPE}
        control={control}
        errors={errors}
        required={true}
        onSubmit={(selectedValue) => setValue("splintType", selectedValue)}
        watch={watchSplintType}
      />
      <SelectorButtonRef
        label="Souhaitez-vous une gouttiere bimaxillaire?"
        name="isBothJaws"
        data={YES_OR_NO_CHOICE}
        control={control}
        errors={errors}
        required={true}
        onSubmit={(selectedValue) => setValue("isBothJaws", selectedValue)}
        watch={watchIsBothJaw}
      />
      <SelectorButtonRef
        label="Type"
        name="splintMaterial"
        data={SPLINT_HARDNESS_CHOICE}
        control={control}
        errors={errors}
        required={true}
        onSubmit={(selectedValue) => setValue("splintMaterial", selectedValue)}
        watch={watchSplintMaterial}
      />
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={handleSubmit(onSubmit)}
          style={{ backgroundColor: STYLE.primary }}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default CreateNewSplint;
