import {
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import {
  Button
} from "@mantine/core";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  useMutation,
} from "@apollo/client"

import {
  ItemType,
  TeethShadeType,
} from "../../types/enums";
import {
  STYLE
} from "../../types/constants";
import SelectorButtonRef from "../SelectorButtonRef";
import SelectInputRef from "../SelectInputRef";
import ShadeSelection from "../shadeSelection";
import {
  CREATE_ANATOMY_ITEM,
  DELETE_MANY_ANATOMY_ITEMS,
} from "../../gql/items";
import {
  deduceShadeType,
} from '../../utils/item.utils';

const CreateNewLay = ({
  productType,
  materialFormChoices,
  anatomyItemChoicesData,
  accountProductPreferences,
  order,
  product_id,
  refetch,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const savedItem = (location?.state?.product?.anatomyItem ?? [])
    .find((item) =>
      item.itemType === ItemType.ONLAY
      || item.itemType === ItemType.INLAY
      || item.itemType === ItemType.OVERLAY
      || item.itemType === ItemType.LAY
  );

  console.log(savedItem);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    defaultValues: {
      layType: savedItem?.itemType,
      material: savedItem?.itemMaterial?.id,
      shade: savedItem?.itemShade?.id,
      shadeType: deduceShadeType(savedItem?.itemShade?.id, order?.user?.id, accountProductPreferences),
    }
  });

  const watchLayType = watch("layType");
  const watchShadeType = watch("shadeType");
  const watchMaterial = watch("material");
  const watchIsMultiShade = watch("isMultiShade");

  const product = (order?.products ?? []).find((product) => product.id === product_id);

  const [createAnatomyItem] = useMutation(CREATE_ANATOMY_ITEM);
  const [deleteManyAnatomyItem] = useMutation(DELETE_MANY_ANATOMY_ITEMS);

  const onSubmit = async(data) => {
    if (savedItem?.teeth){
      await deleteManyAnatomyItem({
        variables: {
          where: {
            productId: {
              equals: product_id,
            },
            teeth: {
              equals: savedItem.teeth,
            },
          },
        }
      })
    }
    await createAnatomyItem({
      variables: {
        args: {
          itemMaterial: data.material
            ? {
                connect: {
                  id: data.material,
                },
              }
            : undefined,
          itemShade: (data.shade && !watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS")
            ? {
                connect: {
                  id: data.shade,
                },
              }
            : undefined,
          itemType: productType === ItemType.LAY ? watchLayType : productType,
          teeth: product.teeth,
          product: {
            connect: {
              id: product.id,
            },
          },
          teethshadeType: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MultiShade : TeethShadeType.SingleShade,
          multiShadeInfo: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
          ? {
            createMany: {
              data: [
                ...data.gingivalShade
                ? [{
                  itemShadeId: data.gingivalShade,
                  teethShadeSide: TeethShadeSide.Gingival,
                }] : [],
                ...data.baseShade
                ? [{
                  itemShadeId: data.baseShade,
                  teethShadeSide: TeethShadeSide.Base,
                }] : [],
                ...data.incisalShade
                ? [{
                  itemShadeId: data.incisalShade,
                  teethShadeSide: TeethShadeSide.Incisal,
                }] : [],
              ],
            },
          } : undefined,
        },
      },
    });
    await refetch();
    navigate(`/orders/${order?.id}/edit/`);
  } 

  return (
    <>
      {productType === ItemType.LAY &&
        <SelectorButtonRef
          label="Veuillez choisir un type de lay"
          data={[
            {
              label: "Inlay",
              value: ItemType.INLAY,
            },
            {
              label: "Onlay",
              value: ItemType.ONLAY,
            },
            {
              label: "Overlay",
              value: ItemType.OVERLAY,
            }
          ]}
          name="layType"
          onSubmit={(selectedValue) => setValue("layType", selectedValue)}
          control={control}
          errors={errors}
          watch={watchLayType}
          required={true}
        />
      }
      <SelectInputRef
        label="Matériau de la couronne"
        name="material"
        data={materialFormChoices}
        control={control}
        errors={errors}
        required={true}
      />
      {watchMaterial !== "METAL_NON_PRECIOUS" &&
        <ShadeSelection
          watchShadeType={watchShadeType}
          control={control}
          errors={errors}
          anatomyItemChoicesData={anatomyItemChoicesData}
          onSelect={(selectedValue) => setValue("shadeType", selectedValue)}
          order={order}
          accountProductPreferences={accountProductPreferences}
        />
      }
      <div style={{ textAlign: "center" }}>
        <Button style={{ backgroundColor: STYLE.primary }} onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </div>
    </>
  )
};

export default CreateNewLay;
