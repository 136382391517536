import React, {useEffect, useState} from "react";
import {
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import {
  GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER,
} from "/@/gql/";
import { Button, Table } from "@mantine/core";
import { getApiUrl } from '../../config';
import {io} from "socket.io-client";
import { SET_LAST_CHAT_OF_ORDER_AS_UNREAD } from "../../gql/chat";
import { SearchBar, TOGGLE_FEATURE } from "@jasper/shared";

const __TAKE__ = 10;

const ChatList = () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [searchText, setSearchText] = useState<string>("");
  const skip = searchParams.get("page")? (parseInt(searchParams.get("page"), 10) * __TAKE__) : 0;
  const pageNumber = parseInt(searchParams.get("page"), 10) ?? 0

  const { data, refetch } = useQuery(
    GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER,
    {
      variables: {
        args: {
          skip: skip,
          take: __TAKE__,
          searchInput: searchText,
        }
      },
      fetchPolicy: "network-only",
    }
  );

  const [markAsUnread] = useMutation(SET_LAST_CHAT_OF_ORDER_AS_UNREAD);
  
  useEffect(() => {
    const socket = io(getApiUrl(),
    {
      query: {
        type: 'notification'
      },
    });
    
    socket.connect()

    socket.on('notification', () => {
      refetch()
    })
    socket.on("disconnect", (reason) => {
      socket.connect();
    });
    return () => {
      socket.disconnect()
      socket.off('connect')
      socket.off('disconnect')
      socket.off('notification')
    }
  }, [])

  useEffect(() => {
    if (searchParams.get("search")){
      setSearchText(searchParams.get("search"));
    }
  }, [searchParams.get("search")]);

  return (
    <>
    <SearchBar
          setSearchText={(value) => setSearchText(value)}
          searchText={searchText}
        />
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Order reference</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Last message</Table.Th>
          <Table.Th>Sender</Table.Th>
          <Table.Th>Patient</Table.Th>
          <Table.Th>Date</Table.Th>
          <Table.Th>Actions</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {data && (data?.getLastUnreadOfEachOrder?.notification ?? []).map((chat) => {
          return (
            <Table.Tr key={chat.order.id} style={chat.read === false ? {fontWeight: 'bold'} : {}}>
              <Table.Td>{chat.order.orderReference}</Table.Td>
              <Table.Td>{chat.order.status}</Table.Td>
              <Table.Td>{chat.message.length > 50 ? chat.message.slice(0,50) + '...' : chat.message}</Table.Td>
              <Table.Td>{chat.userGroup.name}</Table.Td>
              <Table.Td>{chat.order.patient.firstName} {chat.order.patient.lastName}</Table.Td>
              <Table.Td>
                {
                  new Date(chat.createdAt).toDateString() === new Date().toDateString() ?
                    new Date(chat.createdAt).toLocaleTimeString().split(':').splice(0,2).join(':')
                    : new Date(chat.createdAt).toLocaleDateString()
                }
              </Table.Td>
              <Table.Td style={{display: 'flex', gap: '3px', width:'max-content'}}>
                <Button onClick={() => navigate(`/orders/${chat.order.id}`)}>
                  View order
                </Button>
                { chat.read === true && TOGGLE_FEATURE.MARK_AS_VIEW &&
                  <Button 
                    onClick={async () => {
                      await markAsUnread(
                        {
                          variables: {
                            orderId: chat.order.id,
                          },
                        }
                      )
                    }}
                  >
                    Mark as unread
                  </Button>
                }
              </Table.Td>
            </Table.Tr>
          )
        })}
      </Table.Tbody>
    </Table>
    {data && ((data?.getLastUnreadOfEachOrder?.notification ?? []).length > 0) &&
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
          <div>
            {pageNumber > 0 &&
              <Button
                onClick={() => {
                  navigate(`/chats?page=${pageNumber - 1}`)
                }}
              >
                Previous page
              </Button>
            }
          </div>
          <div style={{ marginTop: "1rem" }}>
            Page {pageNumber + 1}
          </div>
          <div>
            {(data?.getLastUnreadOfEachOrder?.notification ?? []).length % __TAKE__ === 0
             && data?.getLastUnreadOfEachOrder?.count !== skip + __TAKE__
             &&
              <Button
                onClick={() => {
                  navigate(`/chats?page=${pageNumber + 1}`)
                }}
              >
                Next page
              </Button>
            }
          </div>
        </div>
      }
    </>
  );
};

export default ChatList;
